<template>
  <v-card>
    <v-progress-linear :active="carregando" indeterminate rounded height="6"></v-progress-linear>

    <h1 class="ml-4">Cadastro de Desaparecido</h1>

    <v-card-text>
      <form id="form" enctype="application/x-www-form-urlencoded">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.titulo"
              label="Nome *"
              @input="setURL"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              class="arquivoAnexo"
              label="Anexar imagem (Cartaz de desaparecido)"
              prepend-icon="mdi-camera"
              v-model="imagem"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="check">
            <v-checkbox
              v-model="form.publicado"
              :label="textoPublicacao"
              color="success"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <v-card-actions class="pa-6">
      <v-row>
        <v-btn
          class="mr-1"
          color="blue darken-1"
          dark
          @click="save"
          :loading="carregando"
        >
          Salvar
        </v-btn>

        <v-btn
          color="gray darken-1"
          dark
          @click="close"
        >
          Pesquisar
        </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message || 'Registro salvo com sucesso!' }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      dialogConfirm: false,
      message: '',
      imagem: null,
      form: {
        titulo: '',
        url: 'desaparecido/',
        publicado: false,
      },
      editando: false,
      carregando: false,
    }
  },

  computed: {
    textoPublicacao() {
      return this.form.publicado ? "Publicar" : "Não publicar";
    },
  },

  methods: {
    serialize() {
      const formData = new FormData();
      Object.keys(this.form).forEach(key => formData.append(key, this.form[key] ?? ''));
      if (this.imagem) {
        formData.append('anexo', this.imagem);
      }
      return formData;
    },

    setURL() {
      this.form.url = `desaparecido/${this.removeAcento(this.form.titulo).replace(/\s+/g, '-')}`;
    },

    close() {
      this.$router.push('/desaparecido');
    },

    save() {
      this.carregando = true;
      api.post(`${process.env.VUE_APP_API}/desaparecido/store`, this.serialize(), {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(response => {
          this.message = response.data.message;
          this.dialogConfirm = true;
        })
        .catch(erro => {
          console.error(erro);
        })
        .finally(() => this.carregando = false);
    },

    closeConfirm() {
      this.dialogConfirm = false;
      this.$router.push('/desaparecido');
    },

    removeAcento(text) {
      return text.toLowerCase()
        .replace(/[ÁÀÂÃ]/gi, 'a')
        .replace(/[ÉÈÊ]/gi, 'e')
        .replace(/[ÍÌÎ]/gi, 'i')
        .replace(/[ÓÒÔÕ]/gi, 'o')
        .replace(/[ÚÙÛ]/gi, 'u')
        .replace(/[Ç]/gi, 'c');
    }
  }
}
</script>

<style scoped>

ul {
  list-style: none;
}

.check {
  margin-bottom: 20px;
}
</style>
