<template>
  <v-card>
    <v-progress-linear :active="editando" indeterminate rounded height="6"></v-progress-linear>
    <h1 class="ml-4">Edição de Desaparecido</h1>

    <v-card-text>
      <form id="form" enctype="application/x-www-form-urlencoded">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.titulo"
              label="Nome *"
              @input="setURL" 
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-6">
            <img :src="`https://www.pc.pa.gov.br/index/ver-foto-desaparecido/${form.id}`" class="col-12" /> 
            <!-- <img :src="`http://localhost:8005/index/ver-foto-desaparecido/${form.id}`" class="col-12" />  -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              class="arquivoAnexo"
              label="Anexar imagem (Cartaz de desaparecido)"
              prepend-icon="mdi-camera"
              v-model="imagem"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="check">
            <v-checkbox
              v-model="form.publicado"
              :label="textoPublicacao"
              color="success"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <v-card-actions class="pa-6">
      <v-btn class="mr-1" color="blue darken-1" dark @click="save" :loading="carregando">Salvar</v-btn>
      <v-btn color="red darken-1" dark @click="close">Pesquisar</v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">{{ message }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">Ok</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "EditDesaparecido",
  data() {
    return {
      dialogConfirm: false,
      message: "",
      imagem: null,
      form: {
        id: this.$route.params.id,
        titulo: "",
        url: "",
        publicado: false,
      },
      editando: true,
      carregando: false,
    };
  },

  computed: {
    textoPublicacao() {
      return this.form.publicado ? "Publicar" : "Não publicar";
    },
  },

  created() {
    console.log(process.env.VUE_APP_API);
    api.get(`${process.env.VUE_APP_API}/desaparecido/edit/${this.form.id}`)
      .then((r) => {
        Object.assign(this.form, r.data);
      })
      .finally(() => (this.editando = false));
  },

  methods: {
    serialize() {
      const formData = new FormData();
      Object.keys(this.form).forEach(key => formData.append(key, this.form[key] ?? ''));
      if (this.imagem) {
        formData.append('anexo', this.imagem);
      }
      return formData;
    },

    setURL() {
      this.form.url = `desaparecido/${this.removeAcento(this.form.titulo).replace(/\s+/g, '-')}`;
    },

    close() {
      this.$router.push("/desaparecido");
    },

    save() {
      this.carregando = true;
      api.post(`${process.env.VUE_APP_API}/desaparecido/update`, this.serialize(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true;
        })
        .catch((erro) => {
          console.error(erro);
        })
        .finally(() => (this.carregando = false));
    },

    closeConfirm() {
      this.dialogConfirm = false;
      this.$router.push("/desaparecido");
    },

    removeAcento(text) {
      return text.toLowerCase()
        .replace(/[ÁÀÂÃ]/gi, 'a')
        .replace(/[ÉÈÊ]/gi, 'e')
        .replace(/[ÍÌÎ]/gi, 'i')
        .replace(/[ÓÒÔÕ]/gi, 'o')
        .replace(/[ÚÙÛ]/gi, 'u')
        .replace(/[Ç]/gi, 'c');
    }
  },
};
</script>

<style>
ul {
  list-style: none;
}
</style>
